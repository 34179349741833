<template>
    <div class="app-container">
        <div class="app-top-btn">
            <el-button type="primary" size="medium" @click="myFormAdd">
                新增充值
            </el-button>
        </div>
        <el-table :data="list" border fit highlight-current-row>
            <el-table-column align="center" label="创建时间" width="160">
                <template slot-scope="scope">
                    <span>{{ scope.row.createTime }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="应充人数">
                <template slot-scope="scope">
                    <span>{{ scope.row.totalNumPlan }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="应充总额">
                <template slot-scope="scope">
                    <span>{{ scope.row.totalAmountPlan }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="实充人数">
                <template slot-scope="scope">
                    <span>{{ scope.row.totalNum }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="实充总额">
                <template slot-scope="scope">
                    <span>{{ scope.row.totalAmount }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="提交时间" width="160">
                <template slot-scope="scope">
                    <span>{{ scope.row.rechargeStartTime }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="完成时间" width="160">
                <template slot-scope="scope">
                    <span>{{ scope.row.rechargeCompleteTime }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="状态" width="130">
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.status === 0"
                        type="danger"
                        size="mini"
                        @click="changeStatus('确认充值名单吗？', scope.row, 1)"
                    >
                        确认充值名单
                    </el-button>
                    <el-tag type="danger" v-else-if="scope.row.status === 1">
                        等待银行到账
                    </el-tag>
                    <el-tag type="danger" v-else-if="scope.row.status === 2">
                        正在进行充值
                    </el-tag>
                    <el-tag type="success" v-else-if="scope.row.status === 3">
                        充值已经成功
                    </el-tag>
                    <el-tag type="danger" v-else-if="scope.row.status === 4">
                        充值已经失败
                    </el-tag>
                    <el-tag type="danger" v-else-if="scope.row.status === 5">
                        充值已经作废
                    </el-tag>
                    <el-tag type="danger" v-else-if="scope.row.status === 6">
                        等待充值
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="220">
                <template slot-scope="scope">
                    <el-button
                        type="info"
                        size="mini"
                        @click="getRechargeList(scope.row)"
                    >
                        名单
                    </el-button>
                    <el-button
                        v-if="scope.row.status < 1"
                        type="primary"
                        size="mini"
                        @click="myFormEdit(scope.row)"
                    >
                        编辑
                    </el-button>
                    <el-button v-else type="primary" size="mini" disabled>
                        编辑
                    </el-button>
                    <el-button
                        v-if="scope.row.status < 2"
                        type="danger"
                        size="mini"
                        @click="
                            changeStatus(
                                '您确定要作废本次充值吗？',
                                scope.row,
                                5
                            )
                        "
                    >
                        作废
                    </el-button>
                    <el-button v-else type="danger" size="mini" disabled>
                        作废
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--分页-->
        <div class="app-pagination">
            <el-pagination
                background
                layout="total, prev, pager, next"
                :total="listTotal"
                :page-size="listQuery.pageSize"
                :current-page="listQuery.pageNum"
                @current-change="pageNumChange"
            >
            </el-pagination>
        </div>

        <!--选择人员-->
        <el-dialog
            title="选择人员"
            width="622px"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
            :visible.sync="myFormDialog"
        >
            <el-transfer
                filterable
                filter-placeholder="姓名查询"
                :filter-method="myFormFilterMethod"
                :titles="['全选', '全选']"
                :format="{
                    noChecked: '${total}',
                    hasChecked: '${checked}/${total}',
                }"
                :data="myFormList"
                v-model="myFormFields.userId"
            >
                <el-button
                    style="margin-left: 8px; padding: 6px"
                    slot="right-footer"
                    type="primary"
                    size="small"
                    @click="myFormSubmit"
                >
                    提交选中人员为本次充值人员
                </el-button>
            </el-transfer>
        </el-dialog>

        <!--充值明细-->
        <el-dialog
            :title="rechargeTitle"
            :visible.sync="rechargeDialog"
            width="720px"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
        >
            <div class="app-top-btn">
                <el-button type="primary" size="medium" @click="excelOut">
                    导出名单
                </el-button>
            </div>
            <el-table :data="rechargeList" border fit highlight-current-row>
                <el-table-column align="center" label="序号" width="60">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="姓名" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="手机号码" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.phone }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="身份证号码">
                    <template slot-scope="scope">
                        <span>{{ scope.row.cardno }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="餐补标准" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.foodStandard }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="充值状态" width="120">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status <= 1"> 等待充值 </el-tag>
                        <el-tag v-else-if="scope.row.status === 2">
                            正在充值
                        </el-tag>
                        <el-tag
                            type="success"
                            v-else-if="scope.row.status === 3"
                        >
                            充值成功
                        </el-tag>
                        <el-tag
                            type="danger"
                            v-else-if="scope.row.status === 4"
                        >
                            充值失败
                        </el-tag>
                        <el-tag
                            type="danger"
                            v-else-if="scope.row.status === 5"
                        >
                            充值作废
                        </el-tag>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { Export2Excel } from '@/utils/excel'
export default {
    data() {
        return {
            list: [],
            listTotal: 0,
            listQuery: {
                pageNum: 1,
                pageSize: 10,
                type: 1, //1是单位,2是商家
                belongId: '', //商家或单位ID
            },
            myFormType: true, //true为新增，false为编辑
            myFormTitle: '新增',
            myFormDialog: false,
            myFormFields: {},
            myFormList: [],
            myFormFilterMethod(query, item) {
                return item.label.indexOf(query) > -1
            },
            rechargeDialog: false, //充值明细弹窗
            rechargeTitle: '', //充值明细弹窗标题
            rechargeList: [], //充值明细记录
        }
    },
    created() {
        this.listQuery.belongId = this.$store.state.user.userInfo.id
        //获取列表
        this.getList()
        //获取员工列表
        this.getMyFormList()
    },
    methods: {
        //分页处理
        pageNumChange(page) {
            if (page != this.listQuery.pageNum) {
                this.listQuery.pageNum = page
                this.getList()
            }
        },
        //获取列表
        getList() {
            this.$api.rechargelist_page(this.listQuery).then((res) => {
                this.listTotal = res.pageInfo.total
                this.list = res.data
            })
        },
        //选择人员 - 拉取列表
        getMyFormList() {
            this.$api
                .user_select({
                    belongId: this.listQuery.belongId,
                    selectType: 1, //过滤无效人员：充值状态、账号状态、餐补标准大于0
                })
                .then((res) => {
                    this.myFormList = []
                    res.forEach((item) => {
                        this.myFormList.push({
                            key: item.id,
                            label: item.name + ' - ' + item.foodStandard,
                            disabled: false,
                        })
                    })
                })
        },
        //选择人员 - 新增
        myFormAdd() {
            //定义参数
            this.myFormFields = {
                userId: [],
                departmentId: this.listQuery.belongId,
            }
            //显示弹窗
            this.myFormTitle = '新增'
            this.myFormType = true //true为新增，false为编辑
            this.myFormDialog = true
        },
        //选择人员 - 编辑
        myFormEdit(item) {
            //定义参数
            this.myFormFields = {
                id: item.id,
                userId: item.userId,
                departmentId: this.listQuery.belongId,
            }
            //显示弹窗
            this.myFormTitle = '编辑'
            this.myFormType = false //true为新增，false为编辑
            this.myFormDialog = true
        },
        //选择人员 - 提交
        myFormSubmit() {
            if (this.myFormFields.userId.length > 0) {
                const loading = this.$loading({
                    lock: true,
                    text: '人员数据更新中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
                if (this.myFormType) {
                    this.$api
                        .rechargelist_save(this.myFormFields)
                        .then((res) => {
                            loading.close()
                            this.$message({
                                duration: 1000,
                                type: 'success',
                                message: res,
                            })
                            this.myFormDialog = false
                            this.getList()
                        })
                } else {
                    this.$api
                        .rechargelist_update(this.myFormFields)
                        .then((res) => {
                            loading.close()
                            this.$message({
                                duration: 1000,
                                type: 'success',
                                message: res,
                            })
                            this.myFormDialog = false
                            this.getList()
                        })
                }
            } else {
                this.$message({
                    duration: 1000,
                    type: 'error',
                    message: '请先选择人员',
                })
            }
        },
        // 修改状态
        changeStatus(title, item, status) {
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$api
                    .rechargelist_updateRechargeOrderStatus({
                        id: item.id,
                        status: status,
                    })
                    .then((res) => {
                        this.$message({
                            duration: 1000,
                            type: 'success',
                            message: res,
                        })
                        this.getList()
                    })
            })
        },
        // 提交充值
        myFormRecharge(item) {
            this.$confirm('您确定要提交本次充值吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '正在充值，请耐心等待...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
                this.$api
                    .rechargelist_startRecharge({
                        id: item.id,
                    })
                    .then((res) => {
                        loading.close()
                        this.$message({
                            duration: 1000,
                            type: 'success',
                            message: res,
                        })
                        this.getList()
                    })
            })
        },
        // 充值明细
        getRechargeList(item) {
            this.rechargeTitle =
                '应充人数：' +
                item.totalNumPlan +
                '，应充总额：' +
                item.totalAmountPlan
            this.$api
                .rechargelist_item_select({
                    id: item.id,
                })
                .then((res) => {
                    this.rechargeList = []
                    this.rechargeList = res
                    this.rechargeDialog = true
                })
        },
        //导出excel
        excelOut() {
            if (this.rechargeList.length > 0) {
                Export2Excel(
                    '充值名单',
                    ['姓名', '身份证号', '联系电话', '餐补标准', '充值状态'],
                    ['name', 'cardno', 'phone', 'foodStandard', 'statusStr'],
                    this.rechargeList
                )
            } else {
                this.$message({
                    duration: 1000,
                    message: '没有可导出的数据',
                    type: 'error',
                })
                return false
            }
        },
    },
}
</script>
